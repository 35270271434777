import { Avatar, Button, Card, Col, Row } from 'antd';
import React from 'react';

import CriteriaForm from './Form';

const CriteriaList = props => {
  const { dataSource, onAdd } = props;
  return (
    <Row gutter={15}>
      <Col span={6}>
        <Card>
          <CriteriaForm onAdd={onAdd} />
        </Card>
      </Col>
      {dataSource.map(criteria => (
        <Col span={6} key={criteria.image_url}>
          <Card
            extra={<Button icon="delete" type="danger" onClick={() => props.onRemove(criteria)} size="small" />}
            style={{ textAlign: 'center' }}
          >
            <Avatar size={120} shape="square" src={criteria.image_url} />
            <h3
              style={{
                marginTop: '10px',
              }}
            >
              {criteria.name}
            </h3>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CriteriaList;
